<template>
    <b-row>
        <b-col lg="2" class="pr-lg-0">
            <b-row>
                <b-col style="max-height: 640px; overflow: auto">
                    <div class="row">
                        <div class="col"><h4 class="w-100 mb-3">FILTERS</h4></div>
                        <div class="col text-right">
                            <b-button variant="link" v-if="hasFilters" @click="deleteAllFilters" class="pt-0">Clear all</b-button>
                        </div>
                    </div>

                    <b-form-radio-group
                        v-model="inventoryTypeFilter.value"
                        :options="inventoryTypes"
                        button-variant="outline-primary"
                        name="radio-btn-outline"
                        buttons
                        style="width: 100%"
                    ></b-form-radio-group>
                    <hr>

                    <!--LOCATION-->
                    <div class="mb-2">
                        <select id="inventory-filter-location"><option></option></select>
                    </div>
                    <h5 v-for="(location, index) in locationFilter" :key="'location-filter-' + index">
                        <b-badge variant="light" :key="'location-filter-' + index" class="badge-white p-2">
                            {{ substringText(location) }} <b-link @click="removeFilterSelection(locationFilter, index)" class="ml-1 mr-1">X</b-link>
                        </b-badge>
                    </h5>
                    <hr>

                    <!--SITE-->
                    <div class="mb-2">
                        <select id="inventory-filter-site"><option></option></select>
                    </div>
                    <h5 v-for="(site, index) in siteFilter" :key="'site-filter-' + index">
                        <b-badge variant="light" :key="'site-filter-' + index" class="badge-white p-2">
                            {{ substringText(site) }} <b-link @click="removeFilterSelection(siteFilter, index)" class="ml-1 mr-1">X</b-link>
                        </b-badge>
                    </h5>
                    <hr>

                    <!--CT Model Filter-->
                    <div class="mb-2">
                        <select id="inventory-filter-ct-model"><option></option></select>
                    </div>
                    <h5 v-for="(ctmodel, index) in ctmodelFilter" :key="'ct-model-filter-' + index">
                        <b-badge variant="light" :key="'ct-model-filter-' + index" class="badge-white p-2">
                            {{ substringText(ctmodel) }} <b-link @click="removeFilterSelection(ctmodelFilter, index)" class="ml-1 mr-1">X</b-link>
                        </b-badge>
                    </h5>
                    <hr>

                    <!--ENGINE SECTION-->
                    <div class="mb-2">
                        <select id="inventory-filter-engine-section"><option></option></select>
                    </div>
                    <h5 v-for="(ctmodel, index) in engineSectionFilter" :key="'engine-section-filter-' + index">
                        <b-badge variant="light" :key="'engine-section-filter-' + index" class="badge-white p-2">
                            {{ substringText(ctmodel) }} <b-link @click="removeFilterSelection(engineSectionFilter, index)" class="ml-1 mr-1">X</b-link>
                        </b-badge>
                    </h5>
                    <hr>

                    <!--PART NAME-->
                    <div class="mb-2">
                        <b-form-group label="Part name">
                            <b-form-input placeholder="Part name" debounce="300" v-model="partNameFilter.value"></b-form-input>
                        </b-form-group>
                    </div>
                    <hr>

                    <!--CSP NUMBER-->
                    <div class="mb-2">
                        <select id="inventory-filter-csp-number"><option></option></select>
                    </div>
                    <h5 v-for="(cspNumber, index) in cspNumberFilter" :key="'csp-number-filter-' + index">
                        <b-badge variant="light" :key="'csp-number-filter-' + index" class="badge-white p-2">
                            {{ substringText(cspNumber) }} <b-link @click="removeFilterSelection(cspNumberFilter, index)" class="ml-1 mr-1">X</b-link>
                        </b-badge>
                    </h5>
                    <hr>

                    <!--NEXT LOCATION-->
                    <div class="mb-2">
                        <select id="inventory-filter-next-location"><option></option></select>
                    </div>
                    <h5 v-for="(nextLocation, index) in nextLocationFilter" :key="'next-location-filter-' + index">
                        <b-badge variant="light" :key="'next-location-filter-' + index" class="badge-white p-2">
                            {{ substringText(nextLocation) }} <b-link @click="removeFilterSelection(nextLocationFilter, index)" class="ml-1 mr-1">X</b-link>
                        </b-badge>
                    </h5>
                    <hr>

<!--                    &lt;!&ndash;REPAIR LOCATION&ndash;&gt;-->
<!--                    <div class="mb-2">-->
<!--                        <select id="inventory-filter-repair-location"><option></option></select>-->
<!--                    </div>-->
<!--                    <h5 v-for="(repairLocation, index) in repairLocationFilter" :key="'next-location-filter-' + index">-->
<!--                        <b-badge variant="light" :key="'next-location-filter-' + index" class="badge-white p-2">-->
<!--                            {{ substringText(repairLocation) }} <b-link @click="removeFilterSelection(repairLocationFilter, index)" class="ml-1 mr-1">X</b-link>-->
<!--                        </b-badge>-->
<!--                    </h5>-->
<!--                    <hr>-->

                    <!--REPAIR NUMBER-->
                    <b-form-group label="Repairs">
                        <b-input-group>
                            <b-form-input placeholder="Min" v-model="repairsNumber.min"></b-form-input>
                            <b-input-group-prepend is-text><b>-</b></b-input-group-prepend>
                            <b-form-input placeholder="Max" v-model="repairsNumber.max"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="default" @click="addMinMaxValue(repairsNumber, repairsNumberFilter)"><font-awesome-icon icon="chevron-right"></font-awesome-icon></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="mt-2"></div>
                    <h5 v-for="(repairNumber, index) in repairsNumberFilter" :key="'repair-number-filter-' + index">
                        <b-badge variant="light" :key="'repair-number-filter-' + index" class="badge-white p-2">
                            {{ substringText(repairNumber.min) }} <small style="text-transform: lowercase;">to</small> {{ substringText(repairNumber.max) }} <b-link @click="removeFilterSelection(repairsNumberFilter, index)" class="ml-1 mr-1">X</b-link>
                        </b-badge>
                    </h5>
                </b-col>
            </b-row>
        </b-col>
        <b-col>
            <b-alert :show="alertInfoDismissCountDown" dismissible variant="info" @dismissed="alertInfoDismissCountDown=0" class=""
                     @dismiss-count-down="alertInfoCountDownChanged">
                <template v-if="alertInfoType === 'InventoryMovementForm'">
                    <p class="h5"><strong>{{ alertInfoData.movementReason }} MOVEMENT</strong></p>
                    <p class="m-0">
                        <strong>CSP Number:</strong> <b-button class="p-0" size="sm" @click="applyCspNumber(alertInfoData.cspNumber)" variant="link">{{ alertInfoData.cspNumber }}</b-button> /
                        <strong>Previous location:</strong> {{ alertInfoData.oldLocation ? alertInfoData.oldLocation : 'No data' }} /
                        <strong>New location:</strong> {{ alertInfoData.location ? alertInfoData.location : 'No data' }} /
                        <template v-if="alertInfoData.movementReason === 'REPAIR'">
                            <strong>Next location:</strong> {{ alertInfoData.nextLocation ? alertInfoData.nextLocation : 'No data' }} /
                        </template>
                        <strong>Part name:</strong> {{ alertInfoData.partName ? alertInfoData.partName : 'No data' }} /
                        <strong>Notes:</strong> {{ alertInfoData.notes ? alertInfoData.notes : 'No data' }}
                    </p>
                    <template v-if="alertInfoData.movementReason === 'REPAIR' && alertInfoData.repair">
                        <p class="m-0 h5"><strong>Repair data</strong></p>
                        <p class="m-0">
                            <strong>CSP Number:</strong> {{ alertInfoData.repair.cspNumber }} /
                            <strong>Location:</strong> {{ alertInfoData.repair.location ? alertInfoData.repair.location : 'No data' }}
                        </p>
                    </template>
                </template>
                <template v-if="alertInfoType === 'InventoryForm'">
                    <p class="h5"><strong>PART DATA {{ alertInfoData.id > 0 ? 'UPDATED' : 'CREATED' }}</strong></p>
                    <p class="m-0">
                        <strong>CSP Number:</strong> <b-button class="p-0" size="sm" @click="applyCspNumber(alertInfoData.cspNumber)" variant="link">{{ alertInfoData.cspNumber }}</b-button> /
                        <strong>Location:</strong> {{ alertInfoData.location ? alertInfoData.location : 'No data' }} /
                        <strong>Part name:</strong> {{ alertInfoData.partName ? alertInfoData.partName : 'No data' }}
                    </p>
                </template>
                <template v-if="alertInfoType === 'RepairForm'">
                    <p class="h5"><strong>REPAIR DATA {{ alertInfoData.id > 0 ? 'UPDATED' : 'CREATED' }}</strong></p>
                    <p class="m-0">
                        <strong>CSP Number:</strong> {{ alertInfoData.cspNumber }} /
                        <strong>Location:</strong> {{ alertInfoData.location ? alertInfoData.location : 'No data' }} /
                        <strong>Installed:</strong> {{ alertInfoData.installDate ? alertInfoData.installDate : 'No data' }} /
                        <strong>Removed:</strong> {{ alertInfoData.removalDate ? alertInfoData.removalDate : 'No data' }} /
                        <strong>Overhaul PO:</strong> {{ alertInfoData.overhaulPo ? alertInfoData.overhaulPo : 'No data' }} /
                        <strong>New/Repair WO:</strong> {{ alertInfoData.newRepairWo ? alertInfoData.newRepairWo : 'No data' }}
                    </p>
                </template>
                <b-progress class="mt-2" variant="info" :max="alertInfoDismissSecs" :value="alertInfoDismissCountDown" height="4px"></b-progress>
            </b-alert>
            <b-row>
                <b-col cols="3">
                    <b-alert show variant="success" class="m-0 h4">
                        <strong>CSP Quantity: <span class="ml-3">{{ inventory.totalRows | formatNumber }}</span></strong>
                    </b-alert>
                </b-col>
                <b-col class="text-right">
                    <b-button variant="primary" @click="inventoryForm = { id: 0 }">
                        <font-awesome-icon icon="plus"></font-awesome-icon> New
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="my-2">
                <b-col cols="11">
                    <b-input placeholder="Search" v-model="inventory.filter" debounce="300"></b-input>
                </b-col>
                <b-col class="text-right">
                    <b-form-select v-model="inventory.itemsPerPage" id="perPageSelect" size="sm" :options="inventory.pageOptions"></b-form-select>
                </b-col>
            </b-row>
            <b-table sticky-header="70vh" :no-border-collapse="true" :items="loadData" :fields="inventory.fields" :filter="inventory.filter" :current-page="inventory.currentPage"
                 :per-page="inventory.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered small show-empty :responsive="true" :sort-by="inventory.sortBy" :sort-desc="inventory.sortDesc"
                 ref="inventoryTable" class="text-left" table-class="custom-table-bordered" @context-changed="tableContextChanged"
                 :hover="false" thead-tr-class="thead-light">
                <template #cell(rowDetails)="row">
                    <b-button size="sm" @click="row.toggleDetails" variant="link" v-if="row.item.repairs.filter(filterRepairs).length > 0">
                        <font-awesome-icon :icon="row.detailsShowing ? 'chevron-down' : 'chevron-left'"></font-awesome-icon> <span class="pb-1">{{ row.item.repairNumber }}</span>
                    </b-button>
                    <template v-if="row.item.repairs.filter(filterRepairs).length <= 0">{{ row.item.repairNumber }}</template>
                </template>

                <template #row-details="row">
                    <div class="p-0 m-0" style="margin-left: 47px !important;">
                        <b-row>
                            <b-col>
                                <b-table-simple responsive bordered striped :hover="false" class="mb-0" table-class="custom-row-details-table">
                                    <b-tbody>
                                        <b-tr style="background-color: inherit !important;">
                                            <b-th colspan="12" class="text-center">REPAIRS</b-th>
                                        </b-tr>
                                        <b-tr style="background-color: inherit !important;">
                                            <b-th v-if="row.item.repairs.filter(filterRepairs).length > 0"></b-th>
                                            <b-th class="text-center">Superseded CSP</b-th>
                                            <b-th class="text-center">GE Part #</b-th>
                                            <b-th class="text-center">Site / Location</b-th>
                                            <b-th class="text-center">Installed</b-th>
                                            <b-th class="text-center">Removed</b-th>
                                            <b-th class="text-center">Overhaul PO</b-th>
                                            <b-th class="text-center">New / Repair WO</b-th>
                                            <b-th class="text-center">GE Job #</b-th>
                                            <b-th class="text-center">Hours</b-th>
                                            <b-th class="text-center">Starts</b-th>
                                            <b-th class="text-center">Actions</b-th>
                                        </b-tr>
                                        <b-tr v-for="(repair, index) in row.item.repairs.filter(filterRepairs)" :key="'repair-detail-' + index" style="background-color: inherit !important;">
                                            <b-td class="text-center td-details-table">
                                                <b-badge variant="success" class="p-1" style="width: 5%;" v-if="repair.type === 'CURRENT'">C</b-badge>
                                                <b-badge variant="primary" class="p-1" style="width: 5%;" v-if="repair.type === 'REPAIR'">R-{{ repair.position }}</b-badge>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <div :inner-html.prop="repair.cspNumber | highlight(inventory.filter)"></div>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <div v-if="repair.item && repair.item.id > 0" :inner-html.prop="repair.item.partNumber | highlight(inventory.filter)"></div>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <div v-if="repair.site && repair.site.name && repair.site.name !== ''" :inner-html.prop="repair.site.name | highlight(inventory.filter)"></div>
                                                <div :inner-html.prop="repair.location | highlight(inventory.filter)"></div>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <div :inner-html.prop="repair.installDate | highlight(inventory.filter)"></div>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <div :inner-html.prop="repair.removalDate | highlight(inventory.filter)"></div>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <div :inner-html.prop="repair.overhaulPo | highlight(inventory.filter)"></div>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <div :inner-html.prop="repair.newRepairWo | highlight(inventory.filter)"></div>
                                            </b-td>
                                            <b-td class="text-center td-details-table">
                                                <router-link v-if="repair.repairJob && repair.repairJob.id > 0" :to="{ name: 'RepairShow', params: { id: repair.repairJob.id }}">{{ repair.repairJob.repairJob }}</router-link>
                                            </b-td>
                                            <b-td class="text-center td-details-table">{{ repair.hours }}</b-td>
                                            <b-td class="text-center td-details-table">{{ repair.starts }}</b-td>
                                            <b-td class="text-center td-details-table">
                                                <b-button-group size="sm">
                                                    <b-button size="sm" @click="repairForm = repair" v-b-tooltip.hover title="Edit" variant="outline-primary">
                                                        <font-awesome-icon icon="edit"></font-awesome-icon>
                                                    </b-button>
                                                    <b-button size="sm" @click="repairFileId = repair.id" v-b-tooltip.hover title="Files" variant="outline-primary">
                                                        <font-awesome-icon icon="paperclip"></font-awesome-icon>
                                                    </b-button>
                                                </b-button-group>
                                            </b-td>
                                        </b-tr>
                                        <b-tr v-if="row.item.repairs.filter(filterRepairs).length <= 0" style="background-color: inherit !important;">
                                            <b-td colspan="12" class="text-center td-details-table">No data found</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </div>
                </template>

                <template #cell(partsPerSet)="row">
                    <div :inner-html.prop="row.item.partsPerSet | highlight(inventory.filter)"></div>
                </template>

                <template #cell(ctmodel)="row">
                    <div :inner-html.prop="row.item.ctmodel | highlight(inventory.filter)"></div>
                </template>

                <template #cell(engineSection)="row">
                    <div :inner-html.prop="row.item.engineSection | highlight(inventory.filter)"></div>
                </template>

                <template #cell(partName)="row">
                    <div :inner-html.prop="row.item.partName | highlight(inventory.filter)"></div>
                </template>

                <template #cell(siteName)="row">
                    <div :inner-html.prop="row.item.siteName | highlight(inventory.filter)"></div>
                </template>

                <template #cell(location)="row">
                    <div :inner-html.prop="row.item.location | highlight(inventory.filter)"></div>
                </template>

                <template #cell(cspNumber)="row">
                    <div :inner-html.prop="row.item.cspNumber | highlight(inventory.filter)"></div>
                </template>

                <template #cell(itemPartNumber)="row">
                    <div :inner-html.prop="row.item.itemPartNumber | highlight(inventory.filter)"></div>
                </template>

                <template #cell(nextLocation)="row">
                    <div :inner-html.prop="row.item.nextLocation | highlight(inventory.filter)"></div>
                </template>

                <template #cell(nextInstallDate)="row">
                    <div :inner-html.prop="row.item.nextInstallDate | highlight(inventory.filter)"></div>
                </template>

                <template #cell(installDate)="row">
                    <div :inner-html.prop="row.item.installDate | highlight(inventory.filter)"></div>
                </template>

                <template #cell(actions)="row">
                    <b-badge variant="danger" class="p-1 mb-2" style="width: 5%;" v-if="row.item.qty <= 0">SCRAP</b-badge>

                    <b-button-group size="sm">
                        <b-button size="sm" @click="inventoryForm = row.item" v-b-tooltip.hover title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="inventoryMovementForm = row.item" v-b-tooltip.hover title="Register movement" variant="outline-primary">
                            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="repairForm = {id: 0, inventoryDataId: row.item.id, objectType: 'HISTORY' }" v-b-tooltip.hover title="Register repair" variant="outline-primary">
                            <font-awesome-icon icon="hammer"></font-awesome-icon>
                        </b-button>
                    </b-button-group>
                </template>
            </b-table>
            <b-row class="no-gutters">
                <b-col sm="5" md="6">
                    Showing {{ start }} to {{ end }} of {{ inventory.totalRows | formatNumber }} row<template v-if="inventory.totalRows !== 1">s</template>
                </b-col>
                <b-col class="my-2" md="6">
                    <b-pagination v-model="inventory.currentPage" :total-rows="inventory.totalRows"
                                  :per-page="inventory.itemsPerPage" align="right" class="mb-0"></b-pagination>
                </b-col>
            </b-row>

            <InventoryMovementForm :inventory-data="inventoryMovementForm" @reset-inventory-form-id="resetForm" @refresh-inventory-table="$refs.inventoryTable.refresh()"></InventoryMovementForm>
            <InventoryForm :inventory-data="inventoryForm" @reset-inventory-form-id="resetForm" @refresh-inventory-table="$refs.inventoryTable.refresh()"></InventoryForm>
            <RepairForm :repair-data="repairForm" @reset-repair-form-id="resetForm" @refresh-inventory-table="$refs.inventoryTable.refresh()"></RepairForm>
            <RepairFile :repair-id="repairFileId" @reset-repair-id="repairFileId = null"></RepairFile>
        </b-col>
    </b-row>
</template>

<script>
import tableStateMixin from '../../mixins/tableState';
import $ from 'jquery';
import * as constants from '@constants';
import { inventoryDataListUrl, inventoryDataFilterDataUrl } from '@routes';
import InventoryMovementForm from './Movement.vue';
import InventoryForm from './Form.vue';
import RepairForm from './Repair.vue';
import RepairFile from './RepairFile.vue';
import { formatNumber } from '../../js/utilities.js';

export default {
    components: {
        InventoryMovementForm,
        InventoryForm,
        RepairForm,
        RepairFile
    },
    mixins: [tableStateMixin],
    data() {
        return {
            inventory: {
                fields: [
                    // { key: 'rowDetails', label: '', class: 'text-center' },
                    { key: 'partsPerSet', label: 'Parts Per Set', class: 'text-center v-text', thStyle: { 'width': '48px', 'min-width': '38px' }},
                    { key: 'ctmodel', label: 'CTModel', class: 'text-center', thStyle: { width: '8%' }},
                    { key: 'engineSection', label: 'Engine Section', class: 'text-center' },
                    { key: 'siteName', label: 'Site', class: 'text-center' },
                    { key: 'location', label: 'Location', class: 'text-center' },
                    { key: 'partName', label: 'Part name' },
                    { key: 'cspNumber', label: 'CSP', class: 'text-center' },
                    { key: 'itemPartNumber', label: 'GE Part #', class: 'text-center', thStyle: { width: '4%' }},
                    { key: 'nextLocation', label: 'Next Location', class: 'text-center' },
                    { key: 'nextInstallDate', label: 'Next Install', class: 'text-center' },
                    { key: 'installDate', label: 'Installed', class: 'text-center' },
                    { key: 'cyclesNumber', label: '# of cycles', class: 'v-text', thStyle: { width: '3%' }},
                    { key: 'cycleStartsHours', label: 'Starts/Hours per cycle', class: 'v-text', thStyle: { width: '4%' }},
                    { key: 'remainingCyclesNumber', label: '# of Remaining Cycles', class: 'v-text', thStyle: { width: '4%' }},
                    { key: 'remainingHoursStarts', label: 'Remaining Hours/Starts', class: 'v-text', thStyle: { width: '5%' }},
                    { key: 'rowDetails', label: 'Repairs', class: 'text-center v-text', thStyle: { width: '5%' }},
                    { key: 'actions', label: 'Actions', class: 'text-center', thStyle: { width: '4%' }}
                ],
                filters: [
                    { column: 'ctmodel', type: 'ARRAY', value: []},
                    { column: 'engineSection', type: 'ARRAY', value: []},
                    { column: 'partName', type: 'STRING', value: '' },
                    { column: 'location', type: 'ARRAY', value: []},
                    { column: 'cspNumber', type: 'ARRAY', value: []},
                    { column: 'nextLocation', type: 'ARRAY', value: []},
                    { column: 'repairLocation', type: 'ARRAY', value: []},
                    { column: 'repairsNumber', type: 'ARRAY', value: []},
                    { column: 'site', type: 'ARRAY', value: []},
                    { column: 'inventoryType', type: 'STRING', value: 'AVAILABLE' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'b.name',
                sortDesc: false
            },
            authorization: `Bearer ${localStorage.getItem('token')}`,
            repairsNumber: {
                min: '',
                max: ''
            },
            inventoryMovementForm: null,
            inventoryForm: null,
            repairForm: null,
            repairFileId: null,
            alertInfoDismissCountDown: 0,
            alertInfoDismissSecs: 60,
            alertInfoType: '',
            alertInfoData: null,
            inventoryTypes: [
                { text: 'ALL', value: 'ALL' },
                { text: 'AVAILABLE', value: 'AVAILABLE' },
                { text: 'SCRAP', value: 'SCRAP' }
            ]
        };
    },
    mounted() {
        let $this = this;

        $this.restoreTableState($this.inventory, $this.tableState);

        $('#inventory-filter-ct-model').select2({
            placeholder: 'CT Model',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: inventoryDataFilterDataUrl(),
                dataType: 'json',
                method: 'POST',
                data(params) {
                    return {
                        filter: 'ctmodel',
                        excludeData: JSON.stringify($this.ctmodelFilter),
                        search: params.term,
                        filters: JSON.stringify($this.inventory.filters)
                    };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:selecting', function(e) {
            $(this).select2('close');
            e.preventDefault();

            $this.ctmodelFilter.push(e.params.args.data.id);
        });

        $('#inventory-filter-engine-section').select2({
            placeholder: 'Engine Section',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: inventoryDataFilterDataUrl(),
                dataType: 'json',
                method: 'POST',
                data(params) {
                    return {
                        filter: 'engineSection',
                        excludeData: JSON.stringify($this.engineSectionFilter),
                        search: params.term,
                        filters: JSON.stringify($this.inventory.filters)
                    };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:selecting', function(e) {
            $(this).select2('close');
            e.preventDefault();

            $this.engineSectionFilter.push(e.params.args.data.id);
        });

        $('#inventory-filter-location').select2({
            placeholder: 'Location',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: inventoryDataFilterDataUrl(),
                dataType: 'json',
                method: 'POST',
                data(params) {
                    return {
                        filter: 'location',
                        excludeData: JSON.stringify($this.locationFilter),
                        search: params.term,
                        filters: JSON.stringify($this.inventory.filters)
                    };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:selecting', function(e) {
            $(this).select2('close');
            e.preventDefault();

            $this.locationFilter.push(e.params.args.data.id);
        });

        $('#inventory-filter-csp-number').select2({
            placeholder: 'CSP',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: inventoryDataFilterDataUrl(),
                dataType: 'json',
                method: 'POST',
                data(params) {
                    return {
                        filter: 'cspNumber',
                        excludeData: JSON.stringify($this.cspNumberFilter),
                        search: params.term,
                        filters: JSON.stringify($this.inventory.filters)
                    };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:selecting', function(e) {
            $(this).select2('close');
            e.preventDefault();

            $this.cspNumberFilter.push(e.params.args.data.id);
        });

        $('#inventory-filter-next-location').select2({
            placeholder: 'Next Location',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: inventoryDataFilterDataUrl(),
                dataType: 'json',
                method: 'POST',
                data(params) {
                    return {
                        filter: 'nextLocation',
                        excludeData: JSON.stringify($this.nextLocationFilter),
                        search: params.term,
                        filters: JSON.stringify($this.inventory.filters)
                    };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:selecting', function(e) {
            $(this).select2('close');
            e.preventDefault();

            $this.nextLocationFilter.push(e.params.args.data.id);
        });

        $('#inventory-filter-repair-location').select2({
            placeholder: 'Repair Location',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: inventoryDataFilterDataUrl(),
                dataType: 'json',
                method: 'POST',
                data(params) {
                    return {
                        filter: 'repairLocation',
                        excludeData: JSON.stringify($this.repairLocationFilter),
                        search: params.term,
                        filters: JSON.stringify($this.inventory.filters)
                    };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:selecting', function(e) {
            $(this).select2('close');
            e.preventDefault();

            $this.repairLocationFilter.push(e.params.args.data.id);
        });

        $('#inventory-filter-site').select2({
            placeholder: 'Site',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: inventoryDataFilterDataUrl(),
                dataType: 'json',
                method: 'POST',
                data(params) {
                    return {
                        filter: 'site',
                        excludeData: JSON.stringify($this.siteFilter),
                        search: params.term,
                        filters: JSON.stringify($this.inventory.filters)
                    };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:selecting', function(e) {
            $(this).select2('close');
            e.preventDefault();

            $this.siteFilter.push(e.params.args.data.id);
        });
    },
    methods: {
        toggleDetail(item) {
            this.$set(item, '_showDetails', !item._showDetails);
        },
        substringText(text) {
            if (text.length > 25) {
                return text.substring(0, 25) + '...';
            }

            return text;
        },
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.inventory.filters.filter(filter => filter.value && ((typeof filter.value === 'string' && filter.value.trim() !== '') || typeof filter.value === 'object')),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(inventoryDataListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.inventory.totalRows = response.data.totalRows;

                        if (data.filter !== '' || this.repairLocationFilter.length > 0) {
                            response.data.inventories.forEach(data => {
                                if (parseInt(data.validateMatches) === 1) {
                                    data._showDetails = true;
                                }
                            });
                        }

                        return response.data.inventories;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.inventory.sortBy = context.sortBy;
            this.inventory.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.inventory);
        },
        removeFilterSelection(filters, index) {
            filters.splice(index, 1);
        },
        addMinMaxValue(range, rangeFilter) {
            this.removeFilterSelection(rangeFilter, 0);

            if (range.min === '' || isNaN(range.min)) {
                range.min = '0';
            }

            if (range.max === '' || isNaN(range.max)) {
                range.max = '0';
            }

            rangeFilter.push(JSON.parse(JSON.stringify(range)));
            range.min = '';
            range.max = '';
        },
        deleteAllFilters() {
            this.inventory.filters.forEach(filter => filter.column !== 'inventoryType' ? (filter.type === 'ARRAY' ? filter.value = [] : filter.value = '') : '');
        },
        filterRepairs(repair) {
            return repair.type === 'REPAIR' && repair.cspNumber !== '';
        },
        resetForm(data) {
            let type = data.type;
            let object = data.object;

            switch (type) {
                case 'InventoryMovementForm':
                    this.inventoryMovementForm = null;
                    break;
                case 'InventoryForm':
                    this.inventoryForm = null;
                    break;
                case 'RepairForm':
                    this.repairForm = null;
                    break;
            }

            if (object) {
                this.alertInfoType = type;
                this.alertInfoData = object;
                this.alertInfoDismissCountDown = this.alertInfoDismissSecs;
            }
        },
        alertInfoCountDownChanged(alertInfoDismissCountDown) {
            this.alertInfoDismissCountDown = alertInfoDismissCountDown;
        },
        applyCspNumber(cspNumber) {
            let findCspNumber = this.cspNumberFilter.find(value => value === cspNumber);

            if (!findCspNumber) {
                this.cspNumberFilter.push(cspNumber);
            }
        }
    },
    watch: {
        'inventory.filters': {
            handler() {
                this.inventory.currentPage = 1;
                this.$refs.inventoryTable.refresh();
                this.saveTableState(this.$route.name, this.inventory);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.inventory.totalRows === 0 ? 0 : (this.inventory.currentPage - 1) * this.inventory.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.inventory.currentPage - 1) * this.inventory.itemsPerPage;

            return (this.inventory.totalRows - offset) < this.inventory.itemsPerPage ? this.inventory.totalRows : offset + this.inventory.itemsPerPage;
        },
        ctmodelFilter() {
            return this.inventory.filters.find(item => item.column === 'ctmodel').value;
        },
        engineSectionFilter() {
            return this.inventory.filters.find(item => item.column === 'engineSection').value;
        },
        partNameFilter() {
            return this.inventory.filters.find(item => item.column === 'partName');
        },
        locationFilter() {
            return this.inventory.filters.find(item => item.column === 'location').value;
        },
        cspNumberFilter() {
            return this.inventory.filters.find(item => item.column === 'cspNumber').value;
        },
        nextLocationFilter() {
            return this.inventory.filters.find(item => item.column === 'nextLocation').value;
        },
        repairLocationFilter() {
            return this.inventory.filters.find(item => item.column === 'repairLocation').value;
        },
        repairsNumberFilter() {
            return this.inventory.filters.find(item => item.column === 'repairsNumber').value;
        },
        siteFilter() {
            return this.inventory.filters.find(item => item.column === 'site').value;
        },
        inventoryTypeFilter() {
            return this.inventory.filters.find(item => item.column === 'inventoryType');
        },
        hasFilters() {
            return this.inventory.filters.filter(item => item.column !== 'inventoryType' && ((item.type === 'ARRAY' && item.value.length > 0) || (item.type === 'STRING' && item.value !== ''))).length > 0;
        }
    },
    filters: {
        highlight(value, query) {
            if (value == null) {
                value = '';
            }

            let text = query;

            if (value.match(new RegExp(query, 'ig'))) {
                text = value.match(new RegExp(query, 'ig'))[0];
            }

            return value.replace(new RegExp(query, 'ig'), '<span class=\'bg-warning\'>' + text + '</span>');
        },
        formatNumber: formatNumber
    }
};
</script>
<style>
    .badge-white {
        background-color: white !important;
        color: #4e5052 !important;
        border: 1px solid #cbd1d5 !important;
    }

    th.v-text > div {
        writing-mode: vertical-rl !important;
        rotate: 180deg !important;
        max-height: 100px !important;
    }

    th.v-text {
        padding: 5px !important;
        line-height: 22px !important;
    }

    /*.custom-table-bordered:not(.dataTable).custom-table-bordered:not(.c-table).table-bordered td, .custom-table-bordered.c-table:not(.dataTable):not(.c-table).table-bordered td, .custom-table-bordered.c-table:not(.dataTable):not(.c-table).table-bordered th {
        border-right: 0 solid black !important;
    }*/

    .custom-table-bordered td:not(:last-child), .custom-table-bordered th:not(:last-child) {
        border-right: 0 solid #cbd1d5 !important;
    }

    .custom-table-bordered td:not(.td-details-table) {
        border-top: 2px solid #c6cdd2 !important;
    }

    .custom-table-bordered tr:is(:last-child) td:not(.td-details-table) {
        border-bottom: 3px solid #c6cdd2 !important;
    }

    .b-table-details td:not(.td-details-table) {
        padding: 0 !important;
        border-bottom: 0 solid #c6cdd2 !important;
    }

    .custom-row-details-table th {
        border-top: 0 !important;
    }

    .custom-table-bordered > thead > tr > .table-b-table-default {
        background-color: #e9ecef !important;
        color: ##4e5052 !important;
    }
</style>