<template>
    <b-modal :id="modalId" ref="modal" size="lg" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <h5>Repair Files</h5>
        </template>
        <b-overlay :show="isLoading">
            <b-row v-if="repairData">
                <b-col>
                    <b-table-simple responsive bordered striped :hover="false" class="mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th>Name</b-th>
                                <b-th class="text-right" style="width: 10%">Size</b-th>
                                <b-th class="text-center" style="width: 22%">Uploaded</b-th>
                                <b-th>Uploaded By</b-th>
                                <b-th class="text-center" style="width: 8%">Actions</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(file, index) in repairData.files" :key="'repair-file-' + index">
                                <b-td>{{ file.name }}</b-td>
                                <b-td class="text-right">{{ file.size | formatSize }}</b-td>
                                <b-td class="text-center">{{ file.created }}</b-td>
                                <b-td>{{ file.createdBy }}</b-td>
                                <b-td class="text-center">
                                    <b-button-group size="sm">
                                        <b-button size="sm" @click="downloadFile(file)" variant="outline-primary">
                                            <font-awesome-icon icon="download"></font-awesome-icon>
                                        </b-button>
                                        <b-button size="sm" @click="deleteFile(file)" variant="outline-primary">
                                            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                        </b-button>
                                    </b-button-group>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="repairData.files.length <= 0">
                                <b-td colspan="5" class="text-center">No data found</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <br>
            <b-row>
                <b-col>
                    <form id="repair-form-file" class="dropzone" enctype="multipart/form-data">
                        <div class="fallback" >
                            <input id="formFile" type="file" name="form[file]" multiple>
                        </div>
                    </form>
                </b-col>
            </b-row>
        </b-overlay>
        <template #modal-footer>
            <div class="text-right">
                <b-button @click="cancelAction" class="mr-2" variant="default"><font-awesome-icon icon="ban"></font-awesome-icon> Close</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { fileUploadUrl, repairDataGetUrl, fileDeleteUrl, fileDownloadUrl } from '@routes';
import Dropzone from 'dropzone';
import * as constants from '@constants';
import { formatSize } from '@/js/utilities';
import { saveAs } from 'file-saver';

export default {
    name: 'RepairFileModal',
    props: {
        repairId: Number
    },
    data() {
        return {
            modalId: 'repair-file-modal',
            toastTitle: 'Repair',
            isLoading: false,
            authorization: `Bearer ${localStorage.getItem('token')}`,
            repairFileDropzone: null,
            repairData: null
        };
    },
    mounted() {

    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(repairDataGetUrl(this.repairId)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.repairData = response.data.repair;

                    this.$bvModal.show('repair-file-modal');

                    this.$nextTick(() => {
                        this.initDropzone();
                    });
                } else {
                    this.$emit('reset-repair-id');

                    this.$bvToast.toast('There was an error getting the repair data', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$emit('reset-repair-id');

                this.$bvToast.toast('There was an error getting the repair data', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        cancelAction() {
            this.$bvModal.hide(this.modalId);
            this.$emit('reset-repair-id');
        },
        initDropzone() {
            let $this = this;

            this.repairFileDropzone = new Dropzone('#repair-form-file', {
                url: fileUploadUrl,
                headers: { 'Authorization': $this.authorization },
                params() {
                    return {
                        entityId: $this.repairId,
                        entityType: 'REPAIR'
                    };
                }
            });

            if (this.repairFileDropzone) {
                this.repairFileDropzone.on('complete', file => {
                    let response = JSON.parse(file.xhr.response);
                    let findFile = this.repairData.files.find(object => parseInt(object.id) === parseInt(response.file.id));

                    if (!findFile) {
                        this.repairData.files.push(response.file);
                    }

                    this.$bvToast.toast(response.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    this.repairFileDropzone.removeFile(file);
                });
            }
        },
        deleteFile(file) {
            this.$swal({
                title: `Would you like to delete the file?`,
                icon: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    this.axios.delete(fileDeleteUrl(file.id)).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            let fileIndex = this.repairData.files.findIndex(object => parseInt(object.id) === parseInt(file.id));

                            if (fileIndex >= 0) {
                                this.repairData.files.splice(fileIndex, 1);
                            }

                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        console.error(error);

                        this.$bvToast.toast('There was an error delete the file', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        downloadFile(file) {
            this.isLoading = true;

            this.axios.get(fileDownloadUrl(file.id), { responseType: 'blob' }).then(response => {
                saveAs(response.data, file.name);
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('There was an error downloading the file', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    watch: {
        repairId() {
            if (this.repairId !== null) {
                this.loadData();
            }
        }
    },
    filters: {
        formatSize: formatSize
    }
};
</script>