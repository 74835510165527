<template>
    <b-modal :id="modalId" ref="modal" size="lg" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <h5>Historic Repair Data</h5>
        </template>
        <b-overlay :show="isLoading">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveRepair)" id="repair-form" v-if="repair">
                    <RepairForm :repair-data="repair" parent-form="repair-form"></RepairForm>
                </b-form>
            </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
            <div class="text-right">
                <b-button @click="cancelAction" class="mr-2" variant="default"><font-awesome-icon icon="ban"></font-awesome-icon> Cancel</b-button>
                <b-button type="submit" form="repair-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { extend } from 'vee-validate';
import { inventoryDataFilterDataUrl, repairDataSaveUrl, itemDataUrl, repairDataUrl } from '@routes';
import * as constants from '@constants';
import { required } from 'vee-validate/dist/rules';
import $ from 'jquery';
import RepairForm from '../Repair/Form.vue';

extend('required', {
    ...required
});

export default {
    components: {
        RepairForm
    },
    name: 'RepairModal',
    props: {
        repairData: Object
    },
    data() {
        return {
            modalId: 'repair-form-modal',
            toastTitle: 'Repair',
            repair: {
                id: 0,
                cspNumber: '',
                item: null,
                location: '',
                installDate: '',
                removalDate: '',
                overhaulPo: '',
                newRepairWo: '',
                repairJob: null,
                starts: '',
                hours: '',
                inventoryDataId: '',
                site: null
            },
            isLoading: false,
            authorization: `Bearer ${localStorage.getItem('token')}`
        };
    },
    methods: {
        saveRepair() {
            this.$swal({
                title: `Would you like to save the data?`,
                icon: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    this.axios.post(repairDataSaveUrl(), this.repair).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$bvModal.hide(this.modalId);

                            this.$emit('reset-repair-form-id', {
                                type: 'RepairForm',
                                object: this.repair
                            });
                            this.$emit('refresh-inventory-table');
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        console.error(error);

                        this.$bvToast.toast('There was an error saving the repair', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        cancelAction() {
            this.$bvModal.hide(this.modalId);
            this.$emit('reset-repair-form-id', {
                type: 'RepairForm',
                object: null
            });
        }
    },
    watch: {
        repairData() {
            if (this.repairData !== null) {
                if (this.repairData.id > 0) {
                    this.repair = JSON.parse(JSON.stringify(this.repairData));
                } else {
                    this.repair = {
                        id: 0,
                        cspNumber: '',
                        item: null,
                        location: '',
                        installDate: '',
                        removalDate: '',
                        overhaulPo: '',
                        newRepairWo: '',
                        repairJob: null,
                        starts: '',
                        hours: '',
                        inventoryDataId: this.repairData.inventoryDataId,
                        site: null
                    };
                }

                this.$bvModal.show('repair-form-modal');
            }
        }
    }
};
</script>