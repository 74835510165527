<template>
    <b-modal :id="modalId" ref="modal" size="lg" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <h5>Movement</h5>
        </template>
        <b-overlay :show="isLoading">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveInventory)" id="inventory-form" v-if="inventory">
                    <b-row>
<!--                        <b-col cols="2" class="text-left">-->
<!--                            <b-form-group label="Type">-->
<!--                                <b-form-radio v-model="inventory.movementType" name="inventory-movement-type" value="IN" class="custom-radio" :disabled="inventory.id > 0">IN</b-form-radio>-->
<!--                                <b-form-radio v-model="inventory.movementType" name="inventory-movement-type" value="OUT" class="custom-radio">OUT</b-form-radio>-->
<!--                            </b-form-group>-->
<!--                        </b-col>-->
                        <b-col cols="4" class="text-left" v-if="inventory.movementType === 'OUT'">
                            <b-form-group label="Type">
                                <b-form-radio v-model="inventory.movementReason" name="inventory-movement-reason" value="TRANSFER" class="custom-radio">Transfer</b-form-radio>
                                <b-form-radio v-model="inventory.movementReason" name="inventory-movement-reason" value="SCRAP" class="custom-radio">Scrap</b-form-radio>
                                <b-form-radio v-model="inventory.movementReason" name="inventory-movement-reason" value="REPAIR"
                                              class="custom-radio" :disabled="inventoryData && inventoryData.locationType === 'REPAIR'">Repair</b-form-radio>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" class="text-left" v-show="inventory.movementType === 'IN' || (inventory.movementType === 'OUT' && inventory.movementReason === 'TRANSFER') || (inventory.movementType === 'OUT' && inventory.movementReason === 'REPAIR')">
                            <b-form-group label="Site">
                                <select id="inventory-site" :disabled="inventory.movementReason === 'REPAIR'"><option></option></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" class="text-left" v-show="inventory.movementType === 'IN' || (inventory.movementType === 'OUT' && inventory.movementReason === 'TRANSFER') || (inventory.movementType === 'OUT' && inventory.movementReason === 'REPAIR')">
                            <b-form-group label="Location">
                                <select id="inventory-location"><option></option></select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr class="mb-0">
                    <b-row v-if="inventoryData">
                        <b-col>
                            <b-table-simple responsive bordered striped :hover="false" class="mb-0">
                                <b-tbody>
                                    <b-tr class="thead-light">
                                        <b-th class="text-center">Parts Per Set</b-th>
                                        <b-th class="text-center">CT Model</b-th>
                                        <b-th class="text-center">Engine Section</b-th>
                                        <b-th class="text-center">Location</b-th>
                                    </b-tr>
                                    <b-tr class="bg-white">
                                        <b-td class="text-center">{{ inventoryData.partsPerSet ? inventoryData.partsPerSet : 'No data' }}</b-td>
                                        <b-td class="text-center">{{ inventoryData.ctmodel ? inventoryData.ctmodel : 'No data' }}</b-td>
                                        <b-td class="text-center">{{ inventoryData.engineSection ? inventoryData.ctmodel : 'No data' }}</b-td>
                                        <b-td class="text-center">{{ inventoryData.location ? inventoryData.location : 'No data' }}</b-td>
                                    </b-tr>
                                    <b-tr class="thead-light">
                                        <b-th colspan="2">Part name</b-th>
                                        <b-th class="text-center">GE Part #</b-th>
                                        <b-th class="text-center">Installed</b-th>
                                    </b-tr>
                                    <b-tr class="bg-white">
                                        <b-td colspan="2">{{ inventoryData.partName ? inventoryData.partName : 'No data' }}</b-td>
                                        <b-td class="text-center">{{ inventoryData.itemPartNumber ? inventoryData.itemPartNumber : 'No data' }}</b-td>
                                        <b-td class="text-center">{{ inventoryData.installDate ? inventoryData.installDate : 'No data' }}</b-td>
                                    </b-tr>
                                    <template v-if="inventory.movementType === 'OUT' && inventory.movementReason !== 'REPAIR'">
                                        <b-tr class="thead-light">
                                            <b-th class="text-center" colspan="2">CSP</b-th>
                                            <b-th class="text-center">Next Location</b-th>
                                            <b-th class="text-center">Next Install</b-th>
                                        </b-tr>
                                        <b-tr class="bg-white">
                                            <b-td class="text-center" colspan="2">{{ inventoryData.cspNumber ? inventoryData.cspNumber : 'No data' }}</b-td>
                                            <b-td class="text-center">{{ inventoryData.nextLocation ? inventoryData.nextLocation : 'No data' }}</b-td>
                                            <b-td class="text-center">{{ inventoryData.nextInstallDate ? inventoryData.nextInstallDate : 'No data' }}</b-td>
                                        </b-tr>
                                    </template>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                    <b-row v-if="inventory.movementType === 'OUT' && inventory.movementReason === 'REPAIR'">
                        <b-col cols="4" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="New CSP" invalid-feedback="Enter the new CSP">
                                    <b-form-input v-model="inventory.cspNumber" autocomplete="off" placeholder="New CSP"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="4" md="4">
                            <b-form-group label="Next location">
                                <select id="inventory-next-location"><option></option></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4">
                            <b-form-group label="Next install">
                                <b-row class="mx-0 px-0">
                                    <b-col cols="10" class="mx-0 px-0">
                                        <b-form-input v-model="inventory.nextInstallDate" autocomplete="off" placeholder="Next install"></b-form-input>
                                    </b-col>
                                    <b-col class="mx-0 px-0">
                                        <b-form-datepicker
                                            v-model="inventory.nextInstallDate"
                                            button-only
                                            locale="en-US"
                                            style="width: 100%;"
                                        ></b-form-datepicker>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <template v-if="inventory.movementType === 'OUT' && inventory.movementReason === 'REPAIR' && repairData">
                        <hr>
                        <h5>Repair Data</h5>
                        <RepairForm :repair-data="repairData" parent-form="inventory-form"></RepairForm>
                        <hr>
                    </template>
                    <b-row>
                        <b-col>
                            <b-form-group label="Notes">
                                <b-textarea v-model="inventory.notes" placeholder="Notes"></b-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
            <div class="text-right">
                <b-button @click="cancelAction" class="mr-2" variant="default"><font-awesome-icon icon="ban"></font-awesome-icon> Cancel</b-button>
                <b-button type="submit" form="inventory-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { extend } from 'vee-validate';
import { inventoryDataFilterDataUrl, inventoryDataMovementSaveUrl } from '@routes';
import * as constants from '@constants';
import { required } from 'vee-validate/dist/rules';
import $ from 'jquery';
import RepairForm from '../Repair/Form.vue';

extend('required', {
    ...required
});

export default {
    name: 'InventoryForm',
    components: {
        RepairForm
    },
    props: {
        inventoryData: Object
    },
    data() {
        return {
            modalId: 'inventory-movement-form-modal',
            toastTitle: 'Movement',
            inventory: {
                id: 0,
                qty: 1,
                movementType: 'IN',
                movementReason: '',
                notes: '',
                location: '',
                locationType: '',
                cspNumber: '',
                nextLocation: '',
                nextInstallDate: '',
                partName: '',
                oldLocation: '',
                site: null
            },
            isLoading: false,
            authorization: `Bearer ${localStorage.getItem('token')}`,
            locations: [
                { value: 'UNIT', text: 'UNIT' },
                { value: 'WAREHOUSE', text: 'WAREHOUSE' },
                { value: 'REPAIR', text: 'REPAIR' }
            ],
            repairData: null
        };
    },
    methods: {
        saveInventory() {
            this.$swal({
                title: `Would you like to save the movement?`,
                icon: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    if (this.repairData && this.inventory.movementType === 'OUT' && this.inventory.movementReason === 'REPAIR') {
                        this.inventory.repair = this.repairData;
                        this.inventory.location = 'GSC';
                    }

                    this.axios.post(inventoryDataMovementSaveUrl(), this.inventory).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            // this.inventory.location = '';
                            this.$bvModal.hide(this.modalId);
                            this.$emit('reset-inventory-form-id', {
                                type: 'InventoryMovementForm',
                                object: this.inventory
                            });
                            this.$emit('refresh-inventory-table');
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        console.error(error);

                        this.$bvToast.toast('There was an error saving the inventory movement', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        cancelAction() {
            this.$bvModal.hide(this.modalId);
            this.$emit('reset-inventory-form-id', {
                type: 'InventoryMovementForm',
                object: null
            });
        },
        initSelect2(entityType) {
            let $this = this;
            let $select = null;
            let filter = '';
            let object = null;
            let placeholder = '';
            let locationTypes = [];

            switch (entityType) {
                case 'site':
                    $select = $('#inventory-site');
                    object = this.inventory.site;
                    placeholder = 'Site';
                    filter = 'site';
                    break;
                case 'location':
                    $select = $('#inventory-location');
                    object = this.inventory.location;
                    placeholder = 'Location';
                    filter = 'location';

                    if ($this.inventory.movementReason === 'TRANSFER') {
                        locationTypes = ['', 'UNIT', 'WAREHOUSE'];
                    } else if ($this.inventory.movementReason === 'REPAIR') {
                        locationTypes = ['REPAIR'];
                    }
                    break;
                case 'nextLocation':
                    $select = $('#inventory-next-location');
                    object = this.inventory.nextLocation;
                    placeholder = 'Next location';
                    filter = 'location';
                    break;
            }

            if ($select) {
                if ($select.hasClass('select2-hidden-accessible')) {
                    $select.empty().trigger('change').select2('destroy');
                }

                $select.select2({
                    placeholder: placeholder,
                    dropdownParent: $('#inventory-form'),
                    allowClear: true,
                    tags: true,
                    ajax: {
                        headers: {
                            'Authorization': $this.authorization
                        },
                        url: inventoryDataFilterDataUrl(),
                        dataType: 'json',
                        method: 'POST',
                        data(params) {
                            return {
                                filter: filter,
                                excludeData: JSON.stringify([]),
                                search: params.term,
                                site: $this.inventory.site ? $this.inventory.site.id : null,
                                locationTypes: JSON.stringify(locationTypes)
                            };
                        },
                        processResults(response) {
                            return { results: response.data };
                        }
                    },
                    escapeMarkup: markup => markup,
                    templateResult(data) {
                        if (data.loading) {
                            return '<a><span>Buscando ...</span></a>';
                        }

                        return `<strong>${ data.text || data.name }</strong>`;
                    },
                    templateSelection(data) {
                        return `<strong>${ data.text || data.name }</strong>`;
                    }
                }).on('select2:select', event => {
                    let data = event.params.data;

                    switch (entityType) {
                        case 'site':
                            $this.inventory.location = '';
                            $this.inventory.locationType = '';
                            $('#inventory-location').empty().trigger('change');

                            $this.inventory.site = data;
                            break;
                        case 'location':
                            $this.inventory.location = data.id;
                            $this.inventory.locationType = data.locationType;
                            break;
                        case 'nextLocation':
                            $this.inventory.nextLocation = data.id;
                            break;
                    }
                }).on('select2:unselect', () => {
                    $this.inventory.location = '';
                    $this.inventory.locationType = '';

                    switch (entityType) {
                        case 'site':
                            $this.inventory.site = null;
                            break;
                        case 'location':
                            $this.inventory.location = '';
                            $this.inventory.locationType = '';
                            break;
                        case 'nextLocation':
                            $this.inventory.nextLocation = '';
                            break;
                    }
                });

                if (object) {
                    if (entityType === 'site') {
                        $select.append(new Option(`<strong>${object.name}</strong>`, object.id, true, true)).trigger('change');
                    } else {
                        $select.append(new Option(`<strong>${object}</strong>`, object, true, true)).trigger('change');
                    }
                }
            }
        }
    },
    watch: {
        inventoryData() {
            if (this.inventoryData !== null) {
                this.inventory = {
                    id: this.inventoryData.id,
                    qty: 1,
                    movementType: (this.inventoryData.id > 0 ? 'OUT' : 'IN'),
                    movementReason: (this.inventoryData.id > 0 ? 'TRANSFER' : ''),
                    notes: '',
                    location: this.inventoryData.nextLocation,
                    locationType: '',
                    cspNumber: this.inventoryData.cspNumber,
                    nextLocation: this.inventoryData.nextLocation,
                    nextInstallDate: this.inventoryData.nextInstallDate,
                    partName: this.inventoryData.partName,
                    oldLocation: this.inventoryData.location,
                    site: null
                };

                this.$bvModal.show('inventory-movement-form-modal');

                this.$nextTick(() => {
                    this.initSelect2('site');
                    this.initSelect2('location');
                });
            }
        },
        'inventory.movementReason'() {
            if (this.inventory.movementReason === 'REPAIR') {
                this.inventory.location = 'GSC';
                this.inventory.locationType = 'REPAIR';

                this.repairData = {
                    id: 0,
                    cspNumber: this.inventoryData.cspNumber,
                    item: this.inventoryData.item,
                    location: this.inventoryData.location,
                    installDate: this.inventoryData.installDate,
                    removalDate: '',
                    overhaulPo: '',
                    newRepairWo: '',
                    repairJob: null,
                    starts: '',
                    hours: '',
                    inventoryDataId: this.inventory.id,
                    objectType: 'NEW',
                    site: this.inventoryData.site
                };

                this.$nextTick(() => {
                    this.initSelect2('nextLocation');
                });
            } else {
                this.inventory.location = this.inventoryData.nextLocation;
                this.inventory.locationType = '';
                this.repairData = null;
            }

            this.inventory.cspNumber = this.inventoryData.cspNumber;
            this.inventory.nextLocation = this.inventoryData.nextLocation;
            this.inventory.nextInstallDate = this.inventoryData.nextInstallDate;
            this.inventory.site = null;

            this.initSelect2('site');
            this.initSelect2('location');
        }
    }
};
</script>
<style>
    .custom-radio {
        display: inline-table !important;
        margin-right: 10px !important;
    }

    .custom-radio label {
        font-weight: normal !important;
        margin-top: 0 !important;
    }
</style>