<template>
    <b-modal :id="modalId" ref="modal" size="lg" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <h5>CSP Data</h5>
        </template>
        <b-overlay :show="isLoading">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveInventory)" id="inventory-form" v-if="inventory">
                    <b-row>
                        <b-col cols="4" md="4">
                            <b-form-group label="Parts per set">
                                <b-form-input v-model="inventory.partsPerSet" autocomplete="off" placeholder="Parts per set"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4">
                            <b-form-group label="CT model">
                                <select id="inventory-form-ct-model"><option></option></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4">
                            <b-form-group label="Engine section">
                                <select id="inventory-form-engine-section"><option></option></select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" md="6">
                            <b-form-group label="Site">
                                <select id="inventory-form-site" :disabled="inventory.id > 0"><option></option></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" md="6">
                            <b-form-group label="Location">
                                <select id="inventory-form-location" :disabled="inventory.id > 0"><option></option></select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" md="6">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Part name" invalid-feedback="Enter the part name">
                                    <b-form-input v-model="inventory.partName" autocomplete="off" placeholder="Part name"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="3" md="3">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="CSP" invalid-feedback="Enter the CSP">
                                    <b-form-input v-model="inventory.cspNumber" autocomplete="off" placeholder="CSP"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="3" md="3">
                            <b-form-group label="GE part #">
                                <select id="inventory-form-item"><option></option></select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4" md="4">
                            <b-form-group label="Next location">
                                <select id="inventory-form-next-location"><option></option></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4">
                            <b-form-group label="Next install">
                                <b-row class="mx-0 px-0">
                                    <b-col cols="10" class="mx-0 px-0">
                                        <b-form-input v-model="inventory.nextInstallDate" autocomplete="off" placeholder="Next install"></b-form-input>
                                    </b-col>
                                    <b-col class="mx-0 px-0">
                                        <b-form-datepicker
                                            v-model="inventory.nextInstallDate"
                                            button-only
                                            locale="en-US"
                                            style="width: 100%;"
                                        ></b-form-datepicker>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4">
                            <b-form-group label="Installed">
                                <b-row class="mx-0 px-0">
                                    <b-col cols="10" class="mx-0 px-0">
                                        <b-form-input v-model="inventory.installDate" autocomplete="off" placeholder="Installed"></b-form-input>
                                    </b-col>
                                    <b-col class="mx-0 px-0">
                                        <b-form-datepicker
                                            v-model="inventory.installDate"
                                            button-only
                                            locale="en-US"
                                            style="width: 100%;"
                                        ></b-form-datepicker>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3" md="3">
                            <b-form-group label="# of cycles">
                                <b-form-input v-model="inventory.cyclesNumber" autocomplete="off" placeholder="# of cycles"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" md="3">
                            <b-form-group label="Starts/Hours per cycle">
                                <b-form-input v-model="inventory.cycleStartsHours" autocomplete="off" placeholder="Starts/Hours per cycle"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" md="3">
                            <b-form-group label="Remaining cycles">
                                <b-form-input v-model="inventory.remainingCyclesNumber" autocomplete="off" placeholder="Remaining cycles"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3" md="3">
                            <b-form-group label="Remaining starts/hours">
                                <b-form-input v-model="inventory.remainingHoursStarts" autocomplete="off" placeholder="Remaining starts/hours"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <template v-if="inventory && inventory.id <= 0 && repairData">
                        <hr>
                        <h5>Repair Data</h5>
                        <RepairForm :repair-data="repairData" parent-form="inventory-form"></RepairForm>
                    </template>
                </b-form>
            </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
            <div class="text-right">
                <b-button @click="cancelAction" class="mr-2" variant="default"><font-awesome-icon icon="ban"></font-awesome-icon> Cancel</b-button>
                <b-button type="submit" form="inventory-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { extend } from 'vee-validate';
import { inventoryDataFilterDataUrl, inventoryDataSaveUrl, itemDataUrl } from '@routes';
import * as constants from '@constants';
import { required } from 'vee-validate/dist/rules';
import $ from 'jquery';
import RepairForm from '../Repair/Form.vue';

extend('required', {
    ...required
});

export default {
    components: {
        RepairForm
    },
    name: 'InventoryForm',
    props: {
        inventoryData: Object
    },
    data() {
        return {
            modalId: 'inventory-data-form-modal',
            toastTitle: 'Inventory',
            inventory: {
                id: 0,
                partsPerSet: '',
                ctmodel: '',
                engineSection: '',
                partName: '',
                location: '',
                locationType: '',
                cspNumber: '',
                nextLocation: '',
                installDate: '',
                nextInstallDate: '',
                cyclesNumber: '',
                cycleStartsHours: '',
                remainingCyclesNumber: '',
                remainingHoursStarts: '',
                item: null,
                qty: 1,
                site: null
            },
            isLoading: false,
            authorization: `Bearer ${localStorage.getItem('token')}`,
            repairData: null
        };
    },
    methods: {
        saveInventory() {
            this.$swal({
                title: `Would you like to save the data?`,
                icon: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    if (this.repairData) {
                        this.inventory.repair = this.repairData;
                    }

                    this.axios.post(inventoryDataSaveUrl(), this.inventory).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$bvModal.hide(this.modalId);

                            this.$emit('reset-inventory-form-id', {
                                type: 'InventoryForm',
                                object: this.inventory
                            });
                            this.$emit('refresh-inventory-table');
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        console.error(error);

                        this.$bvToast.toast('There was an error saving the inventory', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        cancelAction() {
            this.$bvModal.hide(this.modalId);
            this.$emit('reset-inventory-form-id', {
                type: 'InventoryForm',
                object: null
            });
        },
        initPartSelect2() {
            let $select = $('#inventory-form-item');

            if ($select.hasClass('select2-hidden-accessible')) {
                $select.empty().trigger('change').select2('destroy');
            }

            $select.select2({
                placeholder: 'GE part #',
                dropdownParent: $('#inventory-form'),
                allowClear: true,
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: itemDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return { search: params.term };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong>${ data.partNumber }</strong> - ${ data.description }`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.partNumber }</strong>`;
                }
            }).on('select2:select', event => {
                let itemData = event.params.data;

                this.inventory.item = {
                    id: itemData.id,
                    partNumber: itemData.partNumber,
                    description: itemData.description
                };
            }).on('select2:unselect', () => {
                this.inventory.item = null;
            });

            if (this.inventory.item) {
                $select.append(new Option(`<strong>${ this.inventory.item.partNumber }</strong>`, this.inventory.item.id, true, true)).trigger('change');
            }
        },
        initSelect2(entityType) {
            let $this = this;
            let $select = null;
            let placeholder = '';
            let filter = '';
            let object = null;

            switch (entityType) {
                case 'ctmodel':
                    $select = $('#inventory-form-ct-model');
                    placeholder = 'CT model';
                    filter = 'ctmodel';
                    object = this.inventory.ctmodel;
                    break;
                case 'engineSection':
                    $select = $('#inventory-form-engine-section');
                    placeholder = 'Engine section';
                    filter = 'engineSection';
                    object = this.inventory.engineSection;
                    break;
                case 'site':
                    $select = $('#inventory-form-site');
                    placeholder = 'Site';
                    filter = 'site';
                    object = this.inventory.site;
                    break;
                case 'location':
                    $select = $('#inventory-form-location');
                    placeholder = 'Location';
                    filter = 'location';
                    object = this.inventory.location;
                    break;
                case 'nextLocation':
                    $select = $('#inventory-form-next-location');
                    placeholder = 'Next location';
                    filter = 'location';
                    object = this.inventory.nextLocation;
                    break;
            }

            if ($select) {
                if ($select.hasClass('select2-hidden-accessible')) {
                    $select.empty().trigger('change').select2('destroy');
                }

                $select.select2({
                    placeholder: placeholder,
                    dropdownParent: $('#inventory-form'),
                    allowClear: true,
                    tags: true,
                    ajax: {
                        headers: {
                            'Authorization': $this.authorization
                        },
                        url: inventoryDataFilterDataUrl(),
                        dataType: 'json',
                        method: 'POST',
                        data(params) {
                            return {
                                filter: filter,
                                excludeData: JSON.stringify([]),
                                search: params.term,
                                site: $this.inventory.site ? $this.inventory.site.id : null
                            };
                        },
                        processResults(response) {
                            return { results: response.data };
                        }
                    },
                    escapeMarkup: markup => markup,
                    templateResult(data) {
                        if (data.loading) {
                            return '<a><span>Buscando ...</span></a>';
                        }

                        return `<strong>${ data.text || data.name }</strong>`;
                    },
                    templateSelection(data) {
                        return `<strong>${ data.text || data.name }</strong>`;
                    }
                }).on('select2:select', event => {
                    let data = event.params.data;

                    switch (entityType) {
                        case 'ctmodel':
                            this.inventory.ctmodel = data.id;
                            break;
                        case 'engineSection':
                            this.inventory.engineSection = data.id;
                            break;
                        case 'site':
                            this.inventory.site = data;

                            this.inventory.location = '';
                            this.inventory.locationType = '';
                            this.repairData = null;
                            $('#inventory-form-location').empty().trigger('change');
                            break;
                        case 'location':
                            this.inventory.location = data.id;
                            this.inventory.locationType = data.locationType;
                            this.initRepairFormData();
                            break;
                        case 'nextLocation':
                            this.inventory.nextLocation = data.id;
                            break;
                    }
                }).on('select2:unselect', () => {
                    let data = '';

                    switch (entityType) {
                        case 'ctmodel':
                            this.inventory.ctmodel = data;
                            break;
                        case 'engineSection':
                            this.inventory.engineSection = data;
                            break;
                        case 'site':
                            this.inventory.site = null;
                            break;
                        case 'location':
                            this.inventory.location = data;
                            this.inventory.locationType = data;
                            this.repairData = null;
                            break;
                        case 'nextLocation':
                            this.inventory.nextLocation = data;
                            break;
                    }
                });

                if (object) {
                    if (entityType === 'site') {
                        if (object.id !== null) {
                            $select.append(new Option(`<strong>${object.name}</strong>`, object.id, true, true)).trigger('change');
                        }
                    } else {
                        $select.append(new Option(`<strong>${object}</strong>`, object, true, true)).trigger('change');
                    }
                }
            }
        },
        initRepairFormData() {
            if (this.inventory.locationType === 'REPAIR') {
                this.repairData = {
                    id: 0,
                    cspNumber: '',
                    item: null,
                    location: '',
                    installDate: '',
                    removalDate: '',
                    overhaulPo: '',
                    newRepairWo: '',
                    repairJob: null,
                    starts: '',
                    hours: '',
                    inventoryDataId: 0,
                    site: null
                };
            } else {
                this.repairData = null;
            }
        }
    },
    watch: {
        inventoryData() {
            if (this.inventoryData !== null) {
                if (this.inventoryData.id > 0) {
                    this.inventory = JSON.parse(JSON.stringify(this.inventoryData));
                } else {
                    this.inventory = {
                        id: 0,
                        partsPerSet: '',
                        ctmodel: '',
                        engineSection: '',
                        partName: '',
                        location: '',
                        locationType: '',
                        cspNumber: '',
                        nextLocation: '',
                        installDate: '',
                        nextInstallDate: '',
                        cyclesNumber: '',
                        cycleStartsHours: '',
                        remainingCyclesNumber: '',
                        remainingHoursStarts: '',
                        item: null,
                        qty: 1,
                        site: null
                    };
                }

                this.repairData = null;

                this.$bvModal.show('inventory-data-form-modal');

                this.$nextTick(() => {
                    this.initSelect2('ctmodel');
                    this.initSelect2('engineSection');
                    this.initSelect2('site');
                    this.initSelect2('location');
                    this.initSelect2('nextLocation');
                    this.initPartSelect2();
                });
            }
        }
    }
};
</script>