<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col cols="6" md="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-form-group label="Previous CSP" invalid-feedback="Enter the previous CSP">
                            <b-form-input v-model="repairData.cspNumber" autocomplete="off" placeholder="CSP"
                                          :state="errors[0] ? false : null"></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6" md="6">
                    <b-form-group label="Previous GE part #">
                        <select id="repair-form-item"><option></option></select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" md="6">
                    <b-form-group label="Previous Site">
                        <select id="repair-form-site"><option></option></select>
                    </b-form-group>
                </b-col>
                <b-col cols="6" md="6">
                    <b-form-group label="Previous Location">
                        <select id="repair-form-location"><option></option></select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3" md="3">
                    <b-form-group label="Installed">
                        <b-row class="mx-0 px-0">
                            <b-col cols="9" class="mx-0 px-0">
                                <b-form-input v-model="repairData.installDate" autocomplete="off" placeholder="Installed"></b-form-input>
                            </b-col>
                            <b-col class="mx-0 px-0">
                                <b-form-datepicker
                                    v-model="repairData.installDate"
                                    button-only
                                    locale="en-US"
                                    style="width: 100%;"
                                ></b-form-datepicker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
                <b-col cols="3" md="3">
                    <b-form-group label="Removed">
                        <b-row class="mx-0 px-0">
                            <b-col cols="9" class="mx-0 px-0">
                                <b-form-input v-model="repairData.removalDate" autocomplete="off" placeholder="Removed"></b-form-input>
                            </b-col>
                            <b-col class="mx-0 px-0">
                                <b-form-datepicker
                                    v-model="repairData.removalDate"
                                    button-only
                                    locale="en-US"
                                    style="width: 100%;"
                                ></b-form-datepicker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
                <b-col cols="3" md="3">
                    <b-form-group label="Overhaul PO">
                        <b-form-input v-model="repairData.overhaulPo" autocomplete="off" placeholder="Overhaul PO"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3" md="3">
                    <b-form-group label="New/Repair WO">
                        <b-form-input v-model="repairData.newRepairWo" autocomplete="off" placeholder="New/Repair WO"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" md="6">
                    <b-form-group label="GE Job #">
                        <select id="repair-form-repair-job"><option></option></select>
                    </b-form-group>
                </b-col>
                <b-col cols="3" md="3">
                    <b-form-group label="Starts">
                        <b-form-input v-model="repairData.starts" autocomplete="off" placeholder="Starts"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3" md="3">
                    <b-form-group label="Hours">
                        <b-form-input v-model="repairData.hours" autocomplete="off" placeholder="Hours"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { extend } from 'vee-validate';
import { inventoryDataFilterDataUrl, itemDataUrl, repairDataUrl } from '@routes';
import { required } from 'vee-validate/dist/rules';
import $ from 'jquery';

extend('required', {
    ...required
});

export default {
    name: 'RepairForm',
    props: {
        repairData: Object,
        parentForm: String
    },
    data() {
        return {
            isLoading: false,
            authorization: `Bearer ${localStorage.getItem('token')}`
        };
    },
    methods: {
        initPartSelect2() {
            let $select = $('#repair-form-item');

            if ($select.hasClass('select2-hidden-accessible')) {
                $select.empty().trigger('change').select2('destroy');
            }

            $select.select2({
                placeholder: 'Previous GE part #',
                dropdownParent: $('#' + this.parentForm),
                allowClear: true,
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: itemDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return { search: params.term };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong>${ data.partNumber }</strong> - ${ data.description }`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.partNumber }</strong>`;
                }
            }).on('select2:select', event => {
                let itemData = event.params.data;

                this.repairData.item = {
                    id: itemData.id,
                    partNumber: itemData.partNumber,
                    description: itemData.description
                };
            }).on('select2:unselect', () => {
                this.repairData.item = null;
            });

            if (this.repairData.item && this.repairData.item.id > 0) {
                $select.append(new Option(`<strong>${ this.repairData.item.partNumber }</strong>`, this.repairData.item.id, true, true)).trigger('change');
            }
        },
        initSelect2(type) {
            let $this = this;
            let $select = null;
            let placeholder = '';
            let filter = '';
            let object = null;

            switch (type) {
                case 'site':
                    $select = $('#repair-form-site');
                    placeholder = 'Previous Site';
                    filter = 'site';
                    object = this.repairData.site;
                    break;
                case 'location':
                    $select = $('#repair-form-location');
                    placeholder = 'Previous Location';
                    filter = 'location';
                    object = this.repairData.location;
                    break;
            }

            if ($select) {
                if ($select.hasClass('select2-hidden-accessible')) {
                    $select.empty().trigger('change').select2('destroy');
                }

                $select.select2({
                    placeholder: placeholder,
                    dropdownParent: $('#' + this.parentForm),
                    allowClear: true,
                    tags: true,
                    ajax: {
                        headers: {
                            'Authorization': $this.authorization
                        },
                        url: inventoryDataFilterDataUrl(),
                        dataType: 'json',
                        method: 'POST',
                        data(params) {
                            return {
                                filter: filter,
                                excludeData: JSON.stringify([]),
                                search: params.term,
                                site: $this.repairData.site ? $this.repairData.site.id : null
                            };
                        },
                        processResults(response) {
                            return { results: response.data };
                        }
                    },
                    escapeMarkup: markup => markup,
                    templateResult(data) {
                        if (data.loading) {
                            return '<a><span>Buscando ...</span></a>';
                        }

                        return `<strong>${ data.text || data.name }</strong>`;
                    },
                    templateSelection(data) {
                        return `<strong>${ data.text || data.name }</strong>`;
                    }
                }).on('select2:select', event => {
                    let data = event.params.data;

                    switch (type) {
                        case 'site':
                            this.repairData.site = data;

                            this.repairData.location = '';
                            $('#repair-form-location').empty().trigger('change');
                            break;
                        case 'location':
                            this.repairData.location = data.id;
                            break;
                    }
                }).on('select2:unselect', () => {
                    let data = '';

                    switch (type) {
                        case 'site':
                            this.repairData.site = null;
                            break;
                        case 'location':
                            this.repairData.location = data;
                            break;
                    }
                });

                if (object) {
                    if (type === 'site') {
                        if (object.id !== null) {
                            $select.append(new Option(`<strong>${object.name}</strong>`, object.id, true, true)).trigger('change');
                        }
                    } else {
                        $select.append(new Option(`<strong>${object}</strong>`, object, true, true)).trigger('change');
                    }
                }
            }
        },
        initRepairJobSelect2() {
            let $select = $('#repair-form-repair-job');

            if ($select.hasClass('select2-hidden-accessible')) {
                $select.empty().append('<option></option>').select2('destroy');
            }

            $select.empty().trigger('change').select2({
                placeholder: 'Repair',
                dropdownParent: $('#' + this.parentForm),
                allowClear: true,
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: repairDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return {
                            search: params.term,
                            serviceCenterId: 0
                        };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong># ${ data.repairJob }</strong> ${ data.serviceCenterId ? '<br>' + data.serviceCenterName : '' }`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.repairJob }</strong>`;
                }
            }).on('select2:select', event => {
                let repairData = event.params.data;

                this.repairData.repairJob = {
                    id: repairData.id,
                    repairJob: repairData.repairJob
                };
            }).on('select2:unselect', () => {
                this.repairData.repairJob = null;
            });

            if (this.repairData.repairJob && this.repairData.repairJob.id > 0) {
                $select.append(new Option(`<strong>${ this.repairData.repairJob.repairJob }</strong>`, this.repairData.repairJob.id, true, true)).trigger('change');
            }
        }
    },
    mounted() {
        this.initSelect2('site');
        this.initSelect2('location');
        this.initPartSelect2();
        this.initRepairJobSelect2();
    }
};
</script>